import { DismissSquare24Regular } from '@fluentui/react-icons';
import { Divider, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import {
  DisposalRequest,
  DisposalRequestResponseStatus,
  User,
} from '../../types';
import { DetailsRow } from '../DetailsRow';
import { Button } from '../button';
import { DisposalRequestResponseStatusChip, UserChip } from '../chip';
import { CustomActionModal } from './CustomActionModal';

export type DisposalRequestRejectionDialogProps = {
  title?: string;
  request?: DisposalRequest;
  open?: boolean;
  user?: User;
  onClose?: () => void;
  onAction?: (actionButtonTitle: string) => void;
};

export const DisposalRequestRejectionDialog = ({
  title = 'Reject request',
  request,
  open,
  user,
  onClose,
  onAction,
}: DisposalRequestRejectionDialogProps) => {
  const [comment, setComment] = useState('');

  return (
    <CustomActionModal
      title={title}
      onClose={onClose}
      open={open}
      icon={<DismissSquare24Regular />}
      actionButton={
        <Button
          variant='contained'
          onClick={() => onAction(comment)}
          disabled={comment.length === 0}
        >
          Reject
        </Button>
      }
    >
      <DetailsRow label='Request Name' data={request?.Name} size='small' />
      <DetailsRow
        label='Disposal Action'
        data={request?.DisposalAction}
        size='small'
      />
      <DetailsRow
        label='Response'
        data={
          <DisposalRequestResponseStatusChip
            status={DisposalRequestResponseStatus.Rejected}
          />
        }
        size='small'
        alignData
      />
      <DetailsRow
        label='Response By'
        data={<UserChip user={user} />}
        size='small'
      />
      <Divider style={{ margin: '2rem 0 2rem 0' }} />
      <Typography variant='body1'>Comment</Typography>
      <TextField
        sx={{
          marginTop: '0.5rem',
        }}
        fullWidth
        maxRows={5}
        minRows={5}
        multiline
        onChange={(event) => setComment(event.target.value)}
        value={comment}
      />
    </CustomActionModal>
  );
};
export default DisposalRequestRejectionDialog;
