import { config } from '@encompaas/common';
import { useMsalLogout } from '@encompaas/common/auth';
import { AppBarWithActions, Box, Page, ZenDeskSupport, AboutDialog} from '@encompaas/common/components';
import { TEST_CONSTANTS } from '@encompaas/common/constants';
import { useUser, useGetUserServiceAccess } from '@encompaas/common/hooks';
import { useState } from 'react';

// Needed for Zendesk to work
declare global {
  interface Window {
    zE: any;
  }
}

const PageWithAppBar = ({
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  const onLogout = useMsalLogout();
  const user = useUser();
  const [aboutDialogOpen, setAboutDialogOpen] = useState(false);
  const userServiceAccess = useGetUserServiceAccess();


  const tenant = userServiceAccess.data?.AccessibleDatasets[0]?.TenantName;
  const version = process.env.REACT_APP_VERSION;
  const build = config.REACT_APP_ENC_BUILD;
  const commit = config.REACT_APP_ENC_COMMIT;
  const handleHelpOption = (option: string) => {
    switch (option) {
      case 'Help':
        //Clicking 'Help' action opens the EncompaaS Help webpage in another Browser tab
        window.open('https://encompaas.zendesk.com/hc/en-us', '_blank');
        break;

      case 'Community':
        //Clicking 'Community' action opens the EncompaaS Community webpage in another Browser tab
        window.open('https://community.en.support.encompaas.cloud/', '_blank');
        break;

      case 'Contact us':
        //open zenDesk support.
        window.zE("webWidget", "show");
        window.zE("webWidget", "open");
        break;

      case 'About':
        //open about dialog
        setAboutDialogOpen(true);
        break;

      default:
        //Handle additional action logic, if required.
        //...
        break;
    }
  }

  return (
    <Page {...props} background='default' backgroundImage={true}>
      <AppBarWithActions
        user={user}
        color='secondary'
        title={config.TITLE}
        data-testid={TEST_CONSTANTS.APP_BAR}
        onLogout={onLogout}
        onHelpOption={handleHelpOption}
        helpOptions={[
          'Help',
          'Community',
          'Contact us',
          'About',
          `${config.TITLE} v${config.VERSION}`,
        ]}
      />
      <Box
        background='none'
        padding='large'
        style={{ paddingBottom: 0, paddingTop: 0, overflow: 'auto' }}
      >
        {children}
      </Box>

      <AboutDialog
        open={aboutDialogOpen}
        onClose={() => setAboutDialogOpen(false)}
        tenant={tenant}
        version={version}
        build={build}
        commit={commit}
      />


      <ZenDeskSupport/>

    </Page>
  );
};

export default PageWithAppBar;
