import { ErrorAlert, SuccessAlert } from "../hooks";

export type ActionHandler = (
  comment: string,
  delegateToUser?: string
) => Promise<void>;


export type ActionParams = {
  comment: string;
  requestId: string;
  delegateToUserId?: string;
};

export type ApiResponse = {
  error?: {
    data: {
      FriendlyMessage: string;
    };
  };
};

export const handleAction = async (
  serviceCallParams: any,
  executeServiceCall: (params: any) => Promise<any>,
  failureAlert: { title: string; message: string },
  successAlertTexts: { title: string; message: string },
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  errorAlert: ErrorAlert,
  successAlert: SuccessAlert
) => {
  try {
    const response = await executeServiceCall(serviceCallParams);

    if ('error' in response) {
      const _response = response as {
        error: {
          data: {
            FriendlyMessage: string;
          };
        };
      };
      errorAlert.setError(
        failureAlert.title,
        _response.error.data.FriendlyMessage
      );
    } else {
      successAlert.setSuccess(
        successAlertTexts.title,
        successAlertTexts.message
      );
    }
  } catch (err) {
    errorAlert.setError(failureAlert.title, failureAlert.message);
  } finally {
    setDialogOpen(false);
  }
};

